<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef">
        <a-form-model-item prop="outlets_city_adcode" label="城市市场">
          <a-select v-model="outlets_city_adcode" style="width:160px;">
            <a-select-option v-if="!isCityManage" value="">全部</a-select-option>
            <a-select-option v-for="(item, index) in manageCityList"
                   :key="index"
                   :value="item.adcode">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="order_date" label="下单时间">
          <a-range-picker
            style="width:230px"
            v-model="order_date"
            valueFormat="YYYY-MM-DD"
          />
        </a-form-model-item>
        <a-form-model-item prop="audit_date" label="审核时间">
          <a-range-picker
            style="width:230px"
            v-model="audit_date"
            valueFormat="YYYY-MM-DD"
          />
        </a-form-model-item>
        <a-form-model-item prop="finish_date" label="完成时间">
          <a-range-picker
            style="width:230px"
            v-model="finish_date"
            valueFormat="YYYY-MM-DD"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit" @click="handlerSearch">导出</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { getFinanceCityList } from "@/api/outlets"
import { 
	exportOrderGoods
} from "@/api/finance/analysis.js"

export default {
  data() {
    return {
      isCityManage: false,
      manageCityList: [],

			order_date: [],
			audit_date: [],
			finish_date: [],
			outlets_city_adcode: "",
    }
  },
  mounted() {
    this.order_date = [
			this.getToday(),
			this.getToday(),
		]
    this.initFinanceCityList()
  },
  methods: {
    // 内部管理的城市列表
    async initFinanceCityList () {
      let list
      try{
        list = JSON.parse(localStorage.getItem('cityList'))
      }catch(e){
        console.log(e)
      }
      // 如果是城市市场账号
      if(list && list.length) {
        this.isCityManage = true
        this.manageCityList = list
        this.outlets_city_adcode = this.manageCityList[0].adcode
      }else{
      // 否则全部城市
        const { code, data } = await getFinanceCityList()
        if (code == 0) {
          this.manageCityList = data.list
        }
      }
    },
    // 搜索
		async handlerSearch() {
			if(this.order_date.length < 2 && this.audit_date.length < 2 && this.finish_date.length < 2){
				this.$message.warning("下单、审核、完成时间至少填写一项")
				return
			}

			let params = {
				outlets_city_adcode: this.outlets_city_adcode
			}
			if(this.order_date.length == 2){
				if(new Date(this.order_date[1]).getTime() - new Date(this.order_date[0]).getTime() > 62 * 24 * 3600 * 1000){
					this.$message.warning("下单时间跨度不能超2个月")
					return
				}
				Object.assign(params, {
					time_start: this.order_date[0] + " 00:00:00",
					time_end:   this.order_date[1] + " 23:59:59",
				})
			}
			if(this.audit_date.length == 2){
				if(new Date(this.audit_date[1]).getTime() - new Date(this.audit_date[0]).getTime() > 62 * 24 * 3600 * 1000){
					this.$message.warning("审核时间跨度不能超2个月")
					return
				}
				Object.assign(params, {
					audit_time_start: this.audit_date[0] + " 00:00:00",
					audit_time_end:   this.audit_date[1] + " 23:59:59",
				})
			}
			if(this.finish_date.length == 2){
				if(new Date(this.finish_date[1]).getTime() - new Date(this.finish_date[0]).getTime() > 62 * 24 * 3600 * 1000){
					this.$message.warning("完成时间跨度不能超2个月")
					return
				}
				Object.assign(params, {
					finish_time_start: this.finish_date[0] + " 00:00:00",
					finish_time_end:   this.finish_date[1] + " 23:59:59",
				})
			}

			// 新窗口打开页面
			exportOrderGoods(params)
		},

		getToday(){
			const today = new Date()
			return this.formatDate(today)
		},

    formatDate(date){
			let year = date.getFullYear()
			let month = date.getMonth()
			let day = date.getDate()
			month = month+1
			if(month < 10){
				month = "0"+month
			}
			if(day < 10){
				day = "0"+day
			}
			return year+"-"+month+"-"+day
    },

  },
}
</script>

<style>
</style>